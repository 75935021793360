@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: arial, helvetica, sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

#root {
  height: 100vh;
  display: grid;
  grid-template-rows: 13% 4fr 1fr;
}

@media (min-width: 450px) {
  #root {
    grid-template-rows: 11% 4fr 1fr;
  }
}

@media (min-width: 768px) {
  #root {
    grid-template-rows: 11% 4fr 1fr;
  }
}

@media (min-width: 1200px) {
  #root {
    grid-template-rows: 11.2% 4fr 1fr;
  }
}

@media (min-width: 1500px) {
  #root {
    grid-template-rows: 10% 4fr 1fr;
  }
}

/* header */

.logo-desc {
  letter-spacing: 2px;
  color: #00075e;
  font-weight: 800;
}

/* home slider */

.carousel__text {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.408));
}

@media (min-width: 768px) {
  .carousel__text {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.973) 25%,
      rgba(255, 255, 255, 0.865) 75%,
      rgba(255, 255, 255, 0.147) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.carousel img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease-in-out;
}

.carousel img.transform.translate-x-0 {
  transform: translateX(0);
}

.carousel img.transform.translate-x-full {
  transform: translateX(100%);
}

.carousel .next__button {
  opacity: 0;
}

.carousel:hover .next__button {
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}

.sub_menu {
  display: none;
}

.menu_item:hover .sub_menu {
  display: flex;
}

/* news */

.new:hover {
  background-color: #00065ed8;
}

.new-title {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2;
  color: #00075e;
  padding: 2px 0px 4px 0px;
}

.new:hover .new-publisher,
.new:hover .new-categories,
.new:hover .new-title,
.new:hover .new-description {
  color: #fff;
}

@media (min-width: 768px) {
  .new-title {
    font-size: 1.2rem;
  }
}

.new_blogs {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('./public/images/new.png');
  height: 35vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.institutes {
  box-shadow: 0 10px 3px rgba(3, 17, 176, 0.851);
}
